
import api from '@/api/co.api'
import user from '@/api/co.user'
import web from '@/api/web/co.web'
import mixPreset from '@/pages/mixins/my/mix.my.preset'

const Groups = mixPreset.ArticleGroups
const Names = mixPreset.ArticleNames

export default {
  data () {
    return {
      uploadUrl: `${api.url.web_util_file_upload}?folder=image`,
      uploadHeader: {},
      content: '',
      article: {},
      grpActive: '',
      grpTabs: [],
      grpSets: {},
      grpNow: {},
      wdTitle: {
        label: '标题',
        max: 35
      },
      wdState: {
        label: '状态',
        items: []
      },
      wdScope: {
        label: '可见范围',
        items: []
      },
      wdCover: {
        label: '封面',
        name: 'upload_cover',
        action: `${api.url.web_util_file_upload}?folder=image`,
        accept: '.jpg,.jpeg,.png,.gif',
        autoUpload: true,
        tip: '只能上传jpg/png/gif文件，且图片不能超过5MB',
        headers: {},
        limit: 1,
        value: '',
        files: []
      },
      wdForm: {
        title: '',
        cover: '',
        state: '',
        accessScope: ''
      },
      wdRule: {
        title: [
          { required: true, message: '请输入发表文章标题', trigger: 'blur' },
          { min: 2, max: 64, message: '长度在 2 到 35 个字符', trigger: 'blur' }
        ],
        cover: [
          { required: true, message: '请设置封面', trigger: 'blur' },
        ]
      },
      btnCreate: {
        text: '新建文章'
      },
      btnSave: {
        loading: false,
        text: '保存'
      },
      btnIssue: {
        loading: false,
        text: '发表'
      },
      btnPreview: {
        text: '预览'
      },
      btnIssueImg: {
        disabled: false,
        text: '去发表图片'
      },
      btnIssueVid: {
        disabled: false,
        text: '去发表视频'
      },
      rapidHandlers: {
        edit: this.onContentEdit,
        preview: this.onContentPreview,
        remove: this.onContentRemove,
        publish: this.onContentPublish,
        down: this.onContentDown
      }
    }
  },
  created () {
    this.configHeaders()
    this.configGroups()
    this.configScopes()
    this.configStates()
    this.configContents()
    this.loadContents()
  },
  methods: {
    showAlert (type, message) {
      const dlg = this.$refs.dlgAlert
      if (dlg) {
        dlg.show({
          type,
          message
        })
      }
    },
    updateTitle (data) {
      let src = null
      for (const i in this.grpNow.items) {
        const item = this.grpNow.items[i]
        if (item.contentId === data.contentId) {
          src = item
        }
      }
      if (src) {
        this.$set(src, 'title', data.title)
      }
    },
    onContentEdit (index, item) {
      this.article = item
      this.content = item.content
      this.wdForm.title = item.title
      this.wdForm.state = item.state
      this.wdForm.accessScope = item.accessScope
      this.wdForm.cover = item.cover
      this.wdCover.value = item.cover
      if (item.cover) {
        this.wdCover.files = [{
          url: item.cover
        }]
      } else {
        this.wdCover.files = []
      }
    },
    toPreview (item) {
      const dlg = this.$refs['dlgPreview']
      if (dlg) {
        dlg.show(item)
      }
    },
    onContentPreview (index, item) {
      this.toPreview(item)
    },
    toContentDelete (index, item) {
      const me = this
      const executed = function (res) {
        if (res.status) {
          const contentId = item.contentId
          me.grpNow.items.splice(index, 1)
          if (contentId === me.article.contentId) {
            me.resetWidgets()
          }
          me.showAlert('success', '文章已删除')
        } else {
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      web.content.delete({
        id: item.contentId,
        executed
      })
    },
    onContentRemove (index, item) {
      const me = this
      this.$confirm(
        `确定需要删除《${item.title}》吗？`,
        '删除提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      ).then(() => {
        me.toContentDelete(index, item)
      }).catch(action => {
        me.$notify({
          title: '提示',
          message: '取消了本次删除！',
          type: 'info'
        })
      })
    },
    toContentState (item, expectState = 'publish') {
      const desc = web.comm.StateDescs[expectState]
      const me = this
      const executed = function (res) {
        if (res.status) {
          item.state = expectState
          me.$notify({
            title: '成功提示',
            message: `将文章设置为${desc.text}状态成功！`,
            type: 'success'
          })
        } else {
          me.$notify({
            title: '状态设置失败',
            message: `${res.data.code} - ${res.data.desc}`,
            type: 'error'
          })
        }
      }

      web.content.update({
        id: item.contentId,
        data: {
          state: expectState
        },
        executed
      })
    },
    onContentPublish (index, item) {
      const expectState = web.comm.States.PUBLISH
      this.toContentState(item, expectState)
    },
    onContentDown (index, item) {
      const expectState = web.comm.States.DOWN
      this.toContentState(item, expectState)
    },
    onAction (ev) {
      const rapid = ev.rapid
      const handler = this.rapidHandlers[rapid.value]
      if (typeof handler === 'function') {
        handler(ev.index, ev.item)
      }
    },
    configContent (obj) {
      const me = this
      const executed = function (res) {
        const data = obj.params.data
        const pagination = obj.pagination
        const pageSize = parseInt(data.pageSize) || 9
        const length = Math.ceil(res.total / pageSize)
        const pagiNow = {
          ...pagination,
          length
        }
        me.$set(obj, 'pagination', pagiNow)
      }

      obj.params = web.content.getParamsPersonal({
        type: obj.type,
        state: obj.state,
        pageSize: obj.pageSize,
        caches: obj.items,
        executed
      })
    },
    configContents () {
      for (const i in this.grpSets) {
        const grp = this.grpSets[i]
        this.configContent(grp)
      }
    },
    loadContents (reload = true) {
      for (const i in this.grpSets) {
        const grp = this.grpSets[i]
        const params = grp.params
        params.reload = reload
        api.httpx.getItems(params)
      }
    },
    toIssueImage () {
      api.page.navigate(this, 'upload')
    },
    toIssueVideo () {
      api.page.navigate(this, 'issueVideo')
    },
    configHeaders () {
      const obj = user.token.get()
      const headers = {
        token: obj.accessToken,
        uuid: obj.userId
      }
      this.uploadHeader = headers
    },
    configScopes () {
      this.wdScope.items = []
      const descs = web.comm.ScopeDescs
      for (const i in descs) {
        const desc = descs[i]
        this.wdScope.items.push({
          text: desc.text,
          value: desc.value
        })
      }
      const first = this.wdScope.items[0]
      this.wdForm.accessScope = first.value
    },
    configStates () {
      this.wdState.items = []
      const descs = web.comm.StateDescs
      for (const i in descs) {
        const desc = descs[i]
        this.wdState.items.push({
          text: desc.text,
          value: desc.value
        })
      }
      const first = this.wdState.items[0]
      this.wdForm.state = first.value
    },
    configGroups () {
      this.grpActive = Names.DRAFTS

      this.grpSets = {}
      this.grpTabs = []
      for (const key in Groups) {
        const item = Groups[key]
        this.grpSets[key] = item
        this.grpTabs.push({
          title: item.title,
          name: item.name
        })
      }

      this.grpNow = this.grpSets[this.grpActive]
    },
    changeGroup (ev) {
      const item = ev.item
      this.grpNow = this.grpSets[item.name]
      this.grpActive = item.name
    },
    onCoverRemove (file, fileList) {

    },
    onCoverSuccess (res, file) {
      // console.log({ res, file })
      const resp = file.response || {}
      const data = resp.data || {}
      this.wdCover.value = data.fileUrl || ''
      this.wdForm.cover = data.fileUrl || ''
    },
    onCoverBeforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 <= 5

      if (!isLt2M) {
        this.$notify({
          title: '错误提示',
          message: '上传封面图片大小不能超过 5MB!',
          type: 'error'
        })
      }
      return isLt2M
    },
    getData () {
      const result = {
        title: this.wdForm.title,
        cover: this.wdCover.value,
        type: web.comm.ContentTypes.ARTICLE,
        state: this.wdForm.state || web.comm.States.PUBLISH,
        accessScope: this.wdForm.accessScope || web.comm.Scopes.PUBLIC,
        content: this.content
      }
      return result
    },
    create () {
      const me = this
      const data = {
        title: '未命名',
        cover: '',
        type: web.comm.ContentTypes.ARTICLE,
        state: this.wdForm.state || web.comm.States.CREATE,
        accessScope: web.comm.Scopes.PUBLIC,
        content: ''
      }
      const executing = function () {
        me.btnIssue.loading = true
      }

      const executed = function (res) {
        me.btnIssue.loading = false
        if (res.status) {
          const dst = web.content.convertItem(res.data)
          me.grpNow.items.unshift(dst)
          me.article = dst

          me.showAlert('success', '文章新建成功')
          // me.$notify({
          //   title: '成功提示',
          //   message: '文章新建成功！',
          //   type: 'success'
          // })
        } else {
          me.showAlert('success', '文章新建失败')
          // const message = `${res.data.code} - ${res.data.desc}`
          // me.$notify({
          //   title: '新建失败',
          //   message,
          //   type: 'error'
          // })
        }
      }

      web.content.create({
        data,
        executing,
        executed
      })
    },
    insert (issued = false) {
      const me = this
      const data = this.getData()
      let hint = '文章保存'
      if (issued) {
        hint = '文章发表'
        data.state = web.comm.States.PUBLISH
      }
      const executing = function () {
        me.btnIssue.loading = true
      }

      const executed = function (res) {
        me.btnIssue.loading = false
        if (res.status) {
          const dst = web.content.convertItem(res.data)
          me.grpNow.items.unshift(dst)
          me.article = dst
          me.showAlert('success', `${hint}成功`)
        } else {
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      web.content.create({
        data,
        executing,
        executed
      })
    },
    update (issued = false) {
      const me = this
      const data = this.getData()
      let hint = '文章更新'
      if (issued) {
        hint = '文章发表'
        data.state = web.comm.States.PUBLISH
      }
      const executing = function () {
        me.btnIssue.loading = true
      }

      const executed = function (res) {
        me.btnIssue.loading = false
        if (res.status) {
          me.updateTitle(res.data)
          me.showAlert('success', `${hint}成功`)
        } else {
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      web.content.update({
        id: this.article.contentId,
        data,
        executing,
        executed
      })
    },
    issueContent (issued = false) {
      if (this.article.contentId) {
        this.update(issued)
      } else {
        this.insert(issued)
      }
    },
    checkWidgets () {
      let result = ''
      const arr = []

      if (!this.wdForm.title) {
        arr.push('必须填写标题')
      }

      if (!this.wdForm.cover) {
        arr.push('必须上传封面')
      }

      result = arr.join('，')
      return result
    },
    onSubmit (issued = false) {
      const message = this.checkWidgets()
      if (message) {
        this.$notify({
          title: '错误提示',
          message,
          type: 'error'
        })
        return
      }
      this.issueContent(issued)
    },
    onPreview () {
      const message = this.checkWidgets()
      if (message) {
        this.$notify({
          title: '错误提示',
          message,
          type: 'error'
        })
        return
      }

      const data = this.getData()
      this.toPreview(data)
    },
    resetWidgets () {
      this.article = {}
      this.content = ''
      this.wdCover.value = ''
      this.wdCover.files = []
      this.wdForm.title = ''
      this.wdForm.cover = ''
      this.wdForm.state = web.comm.States.CREATE
      this.wdForm.accessScope = web.comm.Scopes.PUBLIC
    },
    onNew () {
      this.resetWidgets()
      this.create()
      // if (this.content) {
      //   const me = this
      //   this.$confirm(
      //     '正在进行内容编辑，是否需要重新创建？',
      //     '删除提示', {
      //       distinguishCancelAndClose: true,
      //       confirmButtonText: '是',
      //       cancelButtonText: '否'
      //     }
      //   ).then(() => {
      //     me.resetWidgets()
      //   }).catch(action => {
      //     me.$notify({
      //       title: '提示',
      //       message: '取消了重新创建！',
      //       type: 'info'
      //     })
      //   })
      // } else {
      //   this.resetWidgets()
      // }
    },
  }
}

<template>
  <land-section
    id="my_upload"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1200px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-card
            class="rounded py-2"
            color="white"
            flat
            tile
            height="1300"
          >
            <article-group-header
              :items="grpTabs"
              :active="grpActive"
              @click="changeGroup"
            />
            <div
              v-if="grpActive === 'drafts'"
              class="co-flex-col co-justify-center co-items-center pa-6 mt-2"
            >
              <v-btn
                outlined
                color="primary"
                @click="onNew"
              >
                {{ btnCreate.text }}
              </v-btn>
            </div>
            <div
              v-else
              class="co-w-full co-h40"
            />
            <article-group-data
              :items="grpNow.items"
              :empty="grpNow.empty"
              :pagination="grpNow.pagination"
              :actions="grpNow.actions"
              @action="onAction"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="9"
          sm="12"
        >
          <v-card
            class="rounded pa-6 issue-article"
            color="white"
            flat
            tile
            height="1300"
          >
            <el-form
              ref="frmArticle"
              :model="wdForm"
              :rules="wdRule"
              label-width="100px"
              label-position="top"
            >
              <el-form-item
                :label="wdTitle.label"
                prop="title"
              >
                <el-input
                  v-model="wdForm.title"
                  :maxlength="wdTitle.max"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                :label="wdCover.label"
                prop="cover"
              >
                <div class="co-flex-col">
                  <el-upload
                    :ref="wdCover.name"
                    :action="wdCover.action"
                    :accept="wdCover.accept"
                    :headers="uploadHeader"
                    :auto-upload="wdCover.autoUpload"
                    :show-file-list="false"
                    :before-upload="onCoverBeforeUpload"
                    :on-success="onCoverSuccess"
                    class="cover-art-uploader"
                  >
                    <el-image
                      v-if="wdCover.value"
                      :src="wdCover.value"
                      fit="cover"
                      :style="{width: smAndDown ? '232px' : '320px', height: smAndDown ? '160px' : '220px', display: 'block'}"
                    />
                    <i
                      v-else
                      class="el-icon-plus"
                      :class="[smAndDown ? 'cover-art-uploader-icon-sm' : 'cover-art-uploader-icon']"
                    />

                    <div
                      slot="tip"
                      class="co-text-xs blue-grey--text"
                      style="line-height: 100%;"
                    >
                      {{ wdCover.tip }}
                    </div>
                  </el-upload>
                </div>
              </el-form-item>

              <el-row :gutter="20">
                <!-- <el-col :span="12">
                  <el-form-item
                    :label="wdState.label"
                    prop="state"
                  >
                    <el-select
                      v-model="wdForm.state"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in wdState.items"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :span="12">
                  <el-form-item
                    :label="wdScope.label"
                    prop="accessScope"
                  >
                    <el-select
                      v-model="wdForm.accessScope"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in wdScope.items"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            <div class="quill-editor">
              <el-upload
                id="uploadImg"
                ref="uploadImg"
                multiple
                style="display: none;"
                :action="uploadUrl"
                :headers="uploadHeader"
                accept=".jpg,.jpeg,.png,.gif"
                :show-file-list="false"
                :on-success="onImageSuccess"
                :before-upload="onImageBeforeUpload"
              >
                上传
              </el-upload>
              <quill-editor
                ref="myQuillEditor"
                v-model="content"
                :options="editorOptions"
                @focus="onEditorFocus($event)"
                @blur="onEditorBlur($event)"
                @change="onEditorChange($event)"
              />
            </div>
            <div class="co-flex-row co-justify-between co-items-center">
              <div class="co-flex-row co-items-center">
                <el-button
                  :loading="btnIssue.loading"
                  type="primary"
                  style="width: 100px;"
                  @click="onSubmit(true)"
                >
                  {{ btnIssue.text }}
                </el-button>
                <el-button
                  :loading="btnSave.loading"
                  type="success"
                  style="width: 100px;"
                  @click="onSubmit(false)"
                >
                  {{ btnSave.text }}
                </el-button>
                <el-button
                  type="info"
                  style="width: 100px;"
                  @click="onPreview"
                >
                  {{ btnPreview.text }}
                </el-button>
              </div>

              <!-- <div class="co-flex-row co-items-center">
                <el-button
                  plain
                  @click="toIssueImage"
                >
                  {{ btnIssueImg.text }}
                </el-button>
                <el-button
                  plain
                  @click="toIssueVideo"
                >
                  {{ btnIssueVid.text }}
                </el-button>
              </div> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <article-preview ref="dlgPreview" />
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>
  import mixMyArticles from '@/pages/mixins/my/mix.my.articles'

  import Quill from 'quill'
  import ImageResize from 'quill-image-resize-module'

  Quill.register('modules/imageResize', ImageResize)

  export default {
    metaInfo: { title: '文章' },
    components: {
      ArticleGroupHeader: () => import('@/pages/sections/my/ArticleGroupHeader.vue'),
      ArticleGroupData: () => import('@/pages/sections/my/ArticleGroupData.vue'),
      ArticlePreview: () => import('@/pages/sections/my/ArticlePreview.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    mixins: [
      mixMyArticles
    ],
    data () {
      return {
        editor: null,
        addRange: [],
        editorOptions: {
          theme: 'snow',
          debug: 'warn',
          placeholder: '书写你的内容',
          readOnly: false,
          modules: {
            imageResize: {
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
              },
              modules: ['Resize', 'DisplaySize']
            },
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '30', '32', '36', '40', '44', '48'] }],
              [{ header: [1, 2, 3, 4, 5, 6] }],
              [{ color: [] }, { background: [] }],
              // [{ 'font': [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image']
            ]
          },
        }
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
    },
    mounted () {
      this.editor = this.$refs.myQuillEditor.quill
      this.editor.getModule('toolbar').addHandler('image', this.imgHandler)
      this.configQuill()
    },
    beforeDestroy () {
      this.editor = null
      delete this.editor
    },
    methods: {
      configQuill () {
        const _toolbar = document.querySelector('.ql-toolbar')
        const _buttons = _toolbar.querySelectorAll('button')
        // const _selects = _toolbar.querySelectorAll('select')

        for (const i in _buttons) {
          const _btn = _buttons[i]
          // console.log('classList: %o', _btn.classList[0])
          let _className = ''
          if (_btn.classList) {
            _className = _btn.classList[0]
          } else {
            _className = _btn.className
          }
          switch (_className) {
            case 'ql-script':
              _btn.value === 'sub' ? _btn.title = '下标' : _btn.title = '上标'
              break
            case 'ql-indent':
              _btn.value === '+1' ? _btn.title = '向右缩进' : _btn.title = '向左缩进'
              break
            case 'ql-bold':
              _btn.title = '加粗'
              break
            case 'ql-color':
              _btn.title = '颜色'
              break
            case 'ql-font':
              _btn.title = '字体'
              break
            case 'ql-code':
              _btn.title = '插入代码'
              break
            case 'ql-italic':
              _btn.title = '斜体'
              break
            case 'ql-link':
              _btn.title = '添加链接'
              break
            case 'ql-background':
              _btn.title = '背景颜色'
              break
            case 'ql-size':
              _btn.title = '字体大小'
              break
            case 'ql-strike':
              _btn.title = '删除线'
              break
            case 'ql-underline':
              _btn.title = '下划线'
              break
            case 'ql-blockquote':
              _btn.title = '引用'
              break
            case 'ql-header':
              _btn.title = '标题'
              break
            case 'ql-list':
              _btn.title = '列表'
              break
            case 'ql-align':
              _btn.title = '文本对齐'
              break
            case 'ql-direction':
              _btn.title = '文本方向'
              break
            case 'ql-code-block':
              _btn.title = '代码块'
              break
            case 'ql-formula':
              _btn.title = '公式'
              break
            case 'ql-image':
              _btn.title = '图片'
              break
            case 'ql-clean':
              _btn.title = '清除字体样式'
              break
          }
        }
      },
      onEditorFocus (editor) {
        // console.log('editor blur!', editor)
      },
      onEditorBlur (editor) {
        // console.log('editor focus!', editor)
      },
      onEditorChange (editor) {
        // console.log('editor change!', editor)
      },
      imgHandler (ev) {
        this.addRange = this.editor.getSelection()
        if (ev) {
          document.querySelector('#uploadImg .el-upload').click()
        }
      },
      onImageSuccess (res, file, fileList) {
        // console.log({ res, file, fileList })
        if (file.status === 'success') {
          const data = res.data || {}
          this.addRange = this.editor.getSelection()
          let _len = 0
          if (this.addRange) {
            _len = this.addRange.index
          }
          this.editor.insertEmbed(_len, 'image', data.fileUrl)
          this.editor.setSelection(_len + 1)
        }
      },
      onImageBeforeUpload (file) {
      }
    }
  }
</script>

<style>
/* .issue-article .el-form-item__label {
  line-height: auto !important;
} */

.issue-article .el-form--label-top .el-form-item__label {
  padding-bottom: 4px !important;
  line-height: 26px !important;
}

.quill-editor {
  height: 700px;
  margin-bottom: 20px;
}

.ql-container.ql-snow {
  height: 600px;
}

.ql-snow .ql-picker {
  line-height: 28px !important;
  height: 28px;
}

.ql-snow .ql-toolbar button {
  width: 28px;
  height: 28px;
}

.cover-art-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .cover-art-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .cover-art-uploader-icon {
    font-size: 32px;
    color: #8c939d;
    width: 320px;
    height: 220x;
    line-height: 220px;
    text-align: center;
  }

  .cover-art-uploader-icon-sm {
    font-size: 32px;
    color: #8c939d;
    width: 232px;
    height: 160x;
    line-height: 160px;
    text-align: center;
  }

  .cover-article {
    width: 320px;
    height: 220x;
    display: block;
  }

  .cover-article-sm {
    width: 232px;
    height: 160x;
    display: block;
  }
</style>
